import React from 'react'
import ReactDOM from 'react-dom'

import restMiddlewareCreator from 'redux-fetch-middleware'
import createSagaMiddleware from 'redux-saga'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { loadingBarMiddleware } from 'react-redux-loading-bar'
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n'
import 'isomorphic-fetch'

import './index.css'
import App from './App'
// import registerServiceWorker from './registerServiceWorker'
import { unregister } from './registerServiceWorker'

import createReducer from './reducers'

import { fetchLoginState } from './actions/auth'
import { initAuthService } from './modules/authService'
import globalRestOptions from './backend/rest_config'

// MARK: - Middlewares
import authHeadersMiddleware from './middlewares/auth'
import fetchTransformMiddleware from './middlewares/fetch_transform'

import translations from './i18n'
import rootSaga from './sagas'
import toast from './middlewares/toast'
import { initAPI } from './api/index'
import {
  createGetServiceDateTime,
  hourOfServiceDateTimeBeforeFlightDateTime,
} from './services/reservation'
import { reservableMiddleware } from './bootstrap/reservableBootstrap'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { matchPath } from 'react-router-dom'
import { accessRoutes } from './modules/accessRoute'

const history = createBrowserHistory()

Sentry.init({
  dsn: 'https://5c9b73e1c37615cd801cda421dab6ed1@o4508306010800128.ingest.us.sentry.io/4508345718341632',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV4BrowserTracingIntegration({
      history,
      routes: accessRoutes,
      matchPath,
    }),
  ],
  // Tracing
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/coral-backoffice\.travelism\.co\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const saga = createSagaMiddleware()

const getServiceDateTime = createGetServiceDateTime({
  hourOfServiceDateTimeBeforeFlightDateTime,
})

const middlewares = [
  thunk,
  reservableMiddleware(getServiceDateTime),
  authHeadersMiddleware,
  fetchTransformMiddleware,
  restMiddlewareCreator(globalRestOptions),
  loadingBarMiddleware({
    promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
  }),
  toast,
  saga,
]

const rootReducer = createReducer({
  getServiceDateTime,
})

export const store = createStore(
  rootReducer,
  process.env['NODE_ENV'] === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    : undefined,
  applyMiddleware(...middlewares),
)

saga.run(rootSaga)

syncTranslationWithStore(store)
store.dispatch(loadTranslations(translations))
store.dispatch(setLocale('en'))

// if (process.env['NODE_ENV'] === 'development')
store.dispatch(fetchLoginState())

initAuthService(store)
initAPI(store)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// registerServiceWorker();
unregister()
